import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container as BlockContainer } from '../components/Blocks'
import { Container, Row, Col } from 'reactstrap'

const DataPrivacy = () => {
    return (
        <Layout>
            <SEO title="Data Privacy" keywords={[`gatsby`, `application`, `react`]} />
            <BlockContainer center={false} className="mt-5">
                <Container>
                    <Row>
                        <Col sm="12">
                            <h1 className="text-center">DATA PRIVACY STATEMENT</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
							<h3>General Statement</h3>

                            <p>
								SIDC recognizes the fundamental human right of privacy by protecting your data in accordance with the laws and standards under Republic Act No. 10173, also known as “DATA PRIVACY ACT OF 2012". SIDC assures that the data we collect, process, store and share are protected and adhere with the Privacy Principles of transparency, legitimate purpose and proportionality.
							</p>

							<h3>Information we collect:</h3>

							<p>
								When you apply as a SIDC member, employee, contractor, consultant, service provider, and apprentice, or transact and interact as a customer to any establishment, branch, authorized representative of the Cooperative, we collect your personal information. This may include the following:
							</p>

							<ul>
								<li>Name, contact details, address, birthdate, age, marital status, gender, education, biometrics;</li>
								<li>Specimen signatures, copy of government ID’s, employment details, emails, social media accounts;</li>
								<li>Financial information, employment history;</li>
								<li>CCTV footage when visiting our office or branches</li>
							</ul>

							<h3>Use/Share</h3>
							<p>
								Your information will be used to validate and process applications and transactions with our business operations and offices such as follows:
							</p>
							<ul>
								<li>Membership, Loans, Savings and Time Deposit applications</li>
								<li>Records purchases and charge transactions</li>
								<li>Application for employment, contractors and service providers</li>
								<li>Receive info for any updates about marketing activities and promos</li>
								<li>Share with third parties Personal Information Processors (PIPs) bound with data sharing agreement</li>
							</ul>

							<h3>Store and Protect</h3>
							<p>
								SIDC assures that the information we collect is stored in a secured location, and only authorized personnel can access. Access to electronic information is bound with policy and non-disclosure agreement with issued security clearance.
							</p>
							<h3>Retention and Disposal</h3>
							<p>
								Your personal information shall be kept for as long as you do business with the cooperative and subject for disposal through deletion and shredding in accordance with the law and internal policy.
							</p>

							<h3>Your rights</h3>
							<p>
								In compliance with the Republic Act 10173, we respect and  recognize your rights to be informed, access your records, object, request for correction or blocking, damages, file a complaint, rectify your information, and data portability, provided that those will not affect the delivery of our services on your transactions with the cooperative.
							</p>
							<p>
								For comments, suggestions, and clarification, feel free to contact us.
							</p>

							<h2>DATA PRIVACY OFFICE</h2>
              <table width="100%" >
              <tr>
              <td width="30%">FRC Floruben Realty Corp.,</td>
              <td width="70%">Contact: 0920-971-0480</td>
              </tr>
              <tr>
              <td width="30%">Pallocan West,</td>
              <td width="70%">Inquiries: dpainquiry.sidc@gmail.com</td>
              </tr>
              <tr>
              <td width="30%">Batangas City 4200</td>
              <td width="70%">Complaints: dpacomplaints.sidc@gmail.com</td>
              </tr>
              <tr>
              <td width="30%">Philippines</td>
              <td width="70%">&nbsp;</td>
              </tr>
              </table>
              <br/>
							<p>
							This Data Privacy Statement is subject for amendments or modification depending on the needs and requirements; based on the laws and regulations under Data Privacy Act of 2012.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
        </Layout>
    )
}

export default DataPrivacy;
